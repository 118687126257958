import {useContext, useState} from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import {ReactComponent as Logo} from "../../assets/images/logo_wrkr-pay.svg";
import {ReactComponent as BoxArrowRight} from "bootstrap-icons/icons/box-arrow-right.svg";
import {ReactComponent as CaretDownFill} from "bootstrap-icons/icons/caret-down-fill.svg";
import Tippy from "@tippyjs/react";
import {ParticipantContext} from "../../App";
import {ReactComponent as Cog} from "../../components/Sidebar/cog.svg";
import {ReactComponent as FileText} from "./file-text.svg";
import {ReactComponent as BoxArrow} from "./box-arrow-up-right.svg";
import {ReactComponent as Bank} from "./bank.svg";
import {ReactComponent as People} from "./people.svg";
import {backToClickSuper, signOut} from "../../utils/siteNavigation";
import { FeatureToggle } from "feature-toggle/FeatureToggle";
import { Feature } from "feature-toggle/features";

const UserDropdown = () => {
    const {participant} = useContext(ParticipantContext);

    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="user-dropdown">
            <Tippy
                theme="light-border"
                placement="bottom-end"
                animation="shift-away"
                arrow={false}
                trigger="click"
                interactive={true}
                offset={[0, 0]}
                onMount={() => setIsExpanded(true)}
                onHide={() => setIsExpanded(false)}
                content={
                    <div>
                        <ul className="py-2">
                            <li>
                                <button
                                    type="button"
                                    className="button button--unstyled button--icon-before"
                                    onClick={(e) => signOut(e)}
                                    data-cy="sign-out"
                                >
                                    <BoxArrowRight className="button--icon-before__icon"/>
                                    Sign out
                                </button>
                            </li>
                        </ul>
                    </div>
                }
            >
                <button
                    className="button button--unstyled button--icon-after"
                    aria-expanded={isExpanded}
                    data-cy="my-account"
                >
                    {`Hi, ${participant?.stpUserDetails?.firstName}`}
                    <CaretDownFill className="button--icon-after__icon"/>
                </button>
            </Tippy>
        </div>
    );
};
const SettingsDropDown = () => {

    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="user-dropdown">
            <Tippy
                theme="light-border"
                placement="bottom-end"
                animation="shift-away"
                arrow={false}
                trigger="click"
                interactive={true}
                offset={[0, 0]}
                onMount={() => setIsExpanded(true)}
                onHide={() => setIsExpanded(false)}
                content={
                    <div className="header__settings_dropdown">
                        <ol className="settings__label">
                            <p className="settings__paragraph__label">WRKR PAY SETTINGS</p>
                            <li className="settings__button__background">
                                <NavLink className="settings__link" aria-expanded={isExpanded}
                                         to="/settings/stp-details/STP2" activeClassName="is-active">
                                    <FileText className="settings__button__label"/>
                                    STP Details
                                </NavLink>
                            </li>
                        </ol>
                        <FeatureToggle feature={Feature.STP_REGISTRATION}>
                        <ol className="settings__label">
                            <p className="settings__paragraph__label">WRKR ADMINISTRATION</p>
                            <li className="settings__button__background">
                                <NavLink className="settings__link" to="#">
                                    <Bank className="settings__button__label"/>
                                    Billing
                                </NavLink>
                                <BoxArrow className="settings__box__arrow"></BoxArrow>
                            </li>
                            <li className="settings__button__background">
                                <NavLink className="settings__link" to="#">
                                    <People className="settings__button__label"/>
                                    User management
                                </NavLink><BoxArrow className="settings__box__arrow"></BoxArrow>
                            </li>
                        </ol>
                        </FeatureToggle>
                    </div>
                }
            >
                <div className="header__settings">
                    <Cog className="header__settings_icon"/>
                </div>
            </Tippy>
        </div>
    );
};
// Header for signed-in users
const ProtectedHeader = () => {
    const location = useLocation();
    return location?.pathname === "/forbidden" ? null : (
        <header className="header">
            <div className="header__inner">
                <div className="header__nav-left">
                    {location.pathname === "/terms-conditions" ?
                        (<div className="go_clicksuper">
                            <button type="link" onClick={(e) => backToClickSuper()}>
                                Return to ClickSuper
                            </button>
                        </div>) :
                        (<div className="header__logo">
                            <Link to="/" aria-label="Homepage">
                                <Logo/>
                            </Link>
                        </div>)
                    }
                </div>
                <div className="header__right">
                    <div className="header__settings_container">
                        <SettingsDropDown/>
                    </div>
                    <div className="header__user"><UserDropdown/></div>
                </div>
            </div>
        </header>
    );
};

export default ProtectedHeader;
